module.exports = [
  {
    "name": "Ilka Fanni",
    "website": "https://ilkafanni.com",
    "email": "ilkafanni@gmail.com",
    "city": "Alameda",
    "phone": "415-948-8056",
    "state":"California"
  },
  {
    "name": "B*E*S*T Doula Service",
    "website": "https://bestdoulas.com",
    "email": "tracy@bestdoulas.com",
    "city": "Alhambra",
    "phone": "818-448-0817",
    "state":"California"
  },
  {
    "name": "OC Placenta Encapsulation",
    "website": "https://ocplacentaencapsulation.com",
    "email": "courtney@ocplacentaencapsulation.com",
    "city": "Aliso Viejo",
    "phone": "949-533-3036",
    "state":"California"
  },
  {
    "name": "Moonstone Midwives Birth Center",
    "website": "https://moonstonemidwives.com",
    "email": "info@MoonstoneMidwives.com",
    "city": "Arcata",
    "phone": "707-633-3009",
    "state":"California"
  },
  {
    "name": "Encircling Mothers Doula Services",
    "website": "https://encirclingmothers.com",
    "email": "shoshana@encirclingmothers.com",
    "city": "Berkeley",
    "phone": "510-209-3996",
    "state":"California"
  },
  {
    "name": "Brilliant Births, LLC",
    "website": "https://brilliantbirths.com",
    "email": "info@brilliantbirths.com",
    "city": "Berkeley",
    "phone": "510-384-5575",
    "state":"California"
  },
  {
    "name": "Kim Winkleman, Doula",
    "website": "https://kimwinkleman.com",
    "email": "kim.winkleman@gmail.com",
    "city": "Berkeley",
    "phone": "510-504-2288",
    "state":"California"
  },
  {
    "name": "Birthways",
    "website": "https://birthways.org",
    "email": "contact@birthways.org",
    "city": "Berkeley",
    "phone": "510-900-9558",
    "state":"California"
  },
  {
    "name": "Douglas Elliman Beverly Hills",
    "website": "https://elliman.com",
    "email": "socialmedia@elliman.com",
    "city": "Beverly Hills",
    "phone": "424-202-3200",
    "state":"California"
  },
  {
    "name": "Ananda Doula Care",
    "website": "https://anandadoula.com",
    "email": "ana@anandadoula.com",
    "city": "Capitola",
    "phone": "831-239-4893",
    "state":"California"
  },
  {
    "name": "Unterseher Raleigh J MD",
    "website": "https://esplanadewomenscare.com",
    "email": "doctor@esplanadewomenscare.com",
    "city": "Chico",
    "phone": "530-332-9288",
    "state":"California"
  },
  {
    "name": "BORN Doulas",
    "website": "https://borndoulas.com",
    "email": "alicia@dreambirthdoula.com",
    "city": "Cupertino",
    "phone": "650-935-5656",
    "state":"California"
  },
  {
    "name": "Birthing Babies ~ A Celebration of Life, Deanna Dawson-Jesus, CD(DONA)",
    "website": "http://birthingbabies.info",
    "email": "Deanna@BirthingBabies.info",
    "city": "Danville",
    "phone": "925-216-7264",
    "state":"California"
  },
  {
    "name": "Stacie Bingham Birth Services",
    "website": "https://staciebingham.com",
    "email": "stacie.bing@gmail.com",
    "city": "Delano",
    "phone": "661-446-4532",
    "state":"California"
  },
  {
    "name": "DOULAS by the BAY LLC",
    "website": "https://doulasbythebay.com",
    "email": "info@doulasbythebay.com",
    "city": "El Cerrito",
    "phone": "415-510-9736",
    "state":"California"
  },
  {
    "name": "Bay Area Birth Support",
    "website": "https://BayAreaBirthSupport.com",
    "email": "Tabitha@BayAreaBirthSupport.com",
    "city": "El Sobrante",
    "phone": "925-360-0817",
    "state":"California"
  },
  {
    "name": "One Moon Doula Services",
    "website": "https://onemoondoula.com",
    "email": "info@onemoondoula.com",
    "city": "Encinitas",
    "phone": "760-652-9296",
    "state":"California"
  },
  {
    "name": "Divine Journey, LLC",
    "website": "https://divinejourney.org",
    "email": "info@divinejourney.org",
    "city": "Fremont",
    "phone": "858.344.7704",
    "state":"California"
  },
  {
    "name": "Blessed Be The Belly Birth Doula Services",
    "website": "https://blessedbethebelly.com",
    "email": "blessedbethebelly@gmail.com",
    "city": "Fresno",
    "phone": "559-287-3231",
    "state":"California"
  },
  {
    "name": "All Families Doula Services",
    "website": "http://allfamiliesdoula.com",
    "email": "allfamiliesdoula@gmail.com",
    "city": "Fresno",
    "phone": "559-554-8671",
    "state":"California"
  },
  {
    "name": "Fresno Birth Collective",
    "website": "https://fresnobirthcollective.com",
    "email": "fresnobirthcollective@gmail.com",
    "city": "Fresno",
    "phone": "559-554-8671",
    "state":"California"
  },
  {
    "name": "Bethany Birth Services | Fresno Doula & Placenta Arts Specialist",
    "website": "https://bethanybirthservices.com",
    "email": "aloha@bethanybirthservices.com",
    "city": "Fresno",
    "phone": "805-380-5530",
    "state":"California"
  },
  {
    "name": "Birth With Treesa",
    "website": "https://birthwithtreesa.com",
    "email": "BirthWithTreesa@gmail.com",
    "city": "Hayward",
    "phone": "510-427-5487",
    "state":"California"
  },
  {
    "name": "Megan Shinkle, Doula",
    "website": "https://meganshinkle.com",
    "email": "meganshinkle.doula@gmail.com",
    "city": "Hercules",
    "phone": "916-317-5841",
    "state":"California"
  },
  {
    "name": "Doula Czarina",
    "website": "https://doulaczarina.com",
    "email": "hello@doulaczarina.com",
    "city": "Hollister",
    "phone": "408-883-2399, 408-832-7996",
    "state":"California"
  },
  {
    "name": "Birth By The Sea",
    "website": "https://birthbythesea.com",
    "email": "birthbythesea@gmail.com",
    "city": "Huntington Beach",
    "phone": "714-612-1126"
  },
  {
    "name": "DoulaLove'sCreation",
    "website": "https://doulalovescreation.com",
    "email": "doulalovescreation@gmail.com",
    "city": "Huntington Beach",
    "phone": "714-965-1694",
    "state":"California"
  },
  {
    "name": "Hero Birth Services",
    "website": "https://herobirthservices.com",
    "email": "herobirthservices@gmail.com",
    "city": "Irvine",
    "phone": "714-681-2445",
    "state":"California"
  },
  {
    "name": "Doulas of Orange County",
    "website": "https://doulasoforangecounty.com",
    "email": "hi@doulasofoc.com",
    "city": "Irvine",
    "phone": "949-791-7164",
    "state":"California"
  },
  {
    "name": "Desert Birth & Wellness",
    "website": "https://desertbirthwellness.com",
    "email": "info@desertbirthwellness.com",
    "city": "Joshua Tree",
    "phone": "760-362-6101",
    "state":"California"
  },
  {
    "name": "New Love Doula",
    "website": "https://newlovedoula.com",
    "email": "sydnie@newlovedoula.com",
    "city": "La Mirada",
    "phone": "",
    "state":"California"
  },
  {
    "name": "My french doula",
    "website": "https://myfrenchdoula.com",
    "email": "yourfrenchdoula@gmail.com",
    "city": "Lake Forest",
    "phone": "424 – 362 – 4387",
    "state":"California"
  },
  {
    "name": "Pear Baby Doula",
    "website": "http://pearbabydoula.com",
    "email": "pearbabydoula@gmail.com",
    "city": "Lancaster",
    "phone": "661-666-2423",
    "state":"California"
  },
  {
    "name": "Antelope Valley Doula",
    "website": "http://antelopevalleydoula.com",
    "email": "antelopevalleydoula@gmail.com",
    "city": "Lancaster",
    "phone": "661-917-7900",
    "state":"California"
  },
  {
    "name": "Rikki The Doula Birth Services",
    "website": "https://rikkithedoula.com",
    "email": "rikkithedoula@gmail.com",
    "city": "Las Vegas",
    "phone": "702-741-7570",
    "state":"California"
  },
  {
    "name": "Andrea Berkey; Three Phases Doula",
    "website": "https://threephasesdoula.com",
    "email": "andrea@threephasesdoula.com",
    "city": "Livermore",
    "phone": "408-307-8046",
    "state":"California"
  },
  {
    "name": "Beautiful Birth",
    "website": "https://beautifulbirth.com",
    "email": "hello@katehennessyphotography.com",
    "city": "Lomita",
    "phone": "310-748-1030",
    "state":"California"
  },
  {
    "name": "Feather and Fawn Birth",
    "website": "https://featherandfawnbirth.com",
    "email": "jessie@featherandfawnbirth.com",
    "city": "Long Beach",
    "phone": "512-593-1656",
    "state":"California"
  },
  {
    "name": "Doulas of Long Beach",
    "website": "https://doulasoflongbeach.com",
    "email": "hi@doulasoflb.com",
    "city": "Long Beach",
    "phone": "562-546-3786",
    "state":"California"
  },
  {
    "name": "Bay Area Doula Training",
    "website": "https://bayareadoula.com",
    "email": "neri.life@gmail.com",
    "city": "Los Altos",
    "phone": "408-480-1389",
    "state":"California"
  },
  {
    "name": "Doulas do Love",
    "website": "https://doulasdolove.com",
    "email": "dawnmbatson@gmail.com",
    "city": "Los Angeles",
    "phone": "310-693-3812",
    "state":"California"
  },
  {
    "name": "Bearth Doula",
    "website": "https://bearthdoula.com",
    "email": "contact@bearthdoula.com",
    "city": "Los Angeles",
    "phone": "323-316-5766",
    "state":"California"
  },
  {
    "name": "GraceFull Birthing",
    "website": "https://gracefull.com",
    "email": "hello@gracefull.com",
    "city": "Los Angeles",
    "phone": "323-486-7134",
    "state":"California"
  },
  {
    "name": "Blissful Baby Doula Services",
    "website": "https://blissfulbabydoulas.com",
    "email": "blissfulbabydoulas@gmail.com",
    "city": "Los Angeles",
    "phone": "323-633-4577",
    "state":"California"
  },
  {
    "name": "Jessica Diggs Birth Services",
    "website": "https://jessicadiggs.com",
    "email": "hello@jessicadiggs.com",
    "city": "Los Angeles",
    "phone": "336-540-4888",
    "state":"California"
  },
  {
    "name": "Kind Kin Doula - Darian Lopez",
    "website": "https://kindkindoula.com",
    "email": "kindkindoula@gmail.com",
    "city": "Los Angeles",
    "phone": "405-630-8999",
    "state":"California"
  },
  {
    "name": "Doula Mellie",
    "website": "https://doulamellie.com",
    "email": "melanie@doulamellie.com",
    "city": "Los Angeles",
    "phone": "424-543-4733",
    "state":"California"
  },
  {
    "name": "Integrative Childbirth Services",
    "website": "https://integrativechildbirth.net",
    "email": "doularena@integrativechildbirth.com",
    "city": "Los Angeles",
    "phone": "562-925-6948",
    "state":"California"
  },
  {
    "name": "Happy Mama Healthy Baby Alliance",
    "website": "https://motherbabysupport.net",
    "email": "doula@motherbabysupport.net",
    "city": "Los Angeles",
    "phone": "626-388-2191",
    "state":"California"
  },
  {
    "name": "Alyssa Ingram Carson City Doula and Placenta Encapsulation",
    "website": "https://carsoncitydoula.com",
    "email": "alyssaeingram@outlook.com",
    "city": "Los Angeles",
    "phone": "775-671-5452",
    "state":"California"
  },
  {
    "name": "Bundle Of Joy Doula Sevices",
    "website": "https://doulalosangeles.com",
    "email": "doulalosangeles@gmail.com",
    "city": "Los Angeles",
    "phone": "818-704-7640",
    "state":"California"
  },
  {
    "name": "Meredith Allen Birth and Postpartum Doula",
    "website": "https://meredithallendoula.com",
    "email": "himere@meredithallendoula.com",
    "city": "Los Angeles",
    "phone": "919-360-4288",
    "state":"California"
  },
  {
    "name": "Silicon Valley Doulas",
    "website": "http://svdoulas.com",
    "email": "svdoulas@gmail.com",
    "city": "Los Gatos",
    "phone": "408-883-5177",
    "state":"California"
  },
  {
    "name": "Joy In Birthing",
    "website": "https://joyinbirthing.com",
    "email": "joyinbirthing@yahoo.com",
    "city": "Marina Del Rey",
    "phone": "310-435-6054",
    "state":"California"
  },
  {
    "name": "HeadFirst Lactation & Doula Services",
    "website": "https://headfirstdoulas.net",
    "email": "Yana@headfirstdoulas.net",
    "city": "Marina Del Rey",
    "phone": "323-240-6002",
    "state":"California"
  },
  {
    "name": "Contra Costa Regional Medical Center",
    "website": "https://cchealth.org",
    "email": "david.goldstein@cchealth.org",
    "city": "Martinez",
    "phone": "925-370-5000",
    "state":"California"
  },
  {
    "name": "Belly to Bliss - Birth Doula Services",
    "website": "https://bellytobliss.com",
    "email": "jennifer@bellytobliss.com",
    "city": "Modesto",
    "phone": "209-322-5271",
    "state":"California"
  },
  {
    "name": "Modesto Lactation and Doula Services",
    "website": "https://modestolactationdoula.com",
    "email": "isabelibclc@Yahoo.com",
    "city": "Modesto",
    "phone": "209-918-4423",
    "state":"California"
  },
  {
    "name": "Avira Wenn | Sacred Path Birth Services",
    "website": "https://sacredpathbirth.com",
    "email": "sacredpathdoula@gmail.com",
    "city": "Monterey",
    "phone": "559-623-3291",
    "state":"California"
  },
  {
    "name": "Harmony Doula Group",
    "website": "https://harmonydoula.com",
    "email": "teri@harmonydoula.com",
    "city": "Mountain House",
    "phone": "408-391-7797",
    "state":"California"
  },
  {
    "name": "Birth Blessings Photography & Doula Services",
    "website": "http://birthblessingsphotography.com",
    "email": "birthblessingsphotography@gmail.com",
    "city": "Murrieta",
    "phone": "760-672-7859",
    "state":"California"
  },
  {
    "name": "Napa Valley Midwives",
    "website": "https://napavalleymidwives.com",
    "email": "info@napavalleymidwives.com",
    "city": "Napa",
    "phone": "707-980-4582",
    "state":"California"
  },
  {
    "name": "Lil Gma Doula",
    "website": "https://lilgmadoula.com",
    "email": "lindalouisecollet@gmail.com",
    "city": "Newbury Park",
    "phone": "805-490-6266",
    "state":"California"
  },
  {
    "name": "Birth Realm Doula Services",
    "website": "https://birthrealm.com",
    "email": "birthrealm@gmail.com",
    "city": "North Bay Area",
    "phone": "310-593-1218",
    "state":"California"
  },
  {
    "name": "Modesto Birth & Beyond Doula Services",
    "website": "https://modestobirthandbeyond.com",
    "email": "hello@modestobirthandbeyond.com",
    "city": "Oakdale",
    "phone": "209-576-5450",
    "state":"California"
  },
  {
    "name": "3 Daughters Doula",
    "website": "https://3daughtersdoula.com",
    "email": "3daughtersdoula@gmail.com",
    "city": "Oakland",
    "phone": "209-743-3349",
    "state":"California"
  },
  {
    "name": "Birth Doula - Renata Provost",
    "website": "https://theinnatedoula.com",
    "email": "renataprovost@gmail.com",
    "city": "Oakland",
    "phone": "510-421-3477",
    "state":"California"
  },
  {
    "name": "Cornerstone Doula Trainings",
    "website": "https://cornerstonedoulatrainings.com",
    "email": "info@cornerstonedoulatrainings.com",
    "city": "Oakland",
    "phone": "510-501-7868",
    "state":"California"
  },
  {
    "name": "Allison Stanton, Birth Doula",
    "website": "https://allisonstantondoula.com",
    "email": "allisonstantondoula@gmail.com",
    "city": "Oakland",
    "phone": "510-502-2779",
    "state":"California"
  },
  {
    "name": "The First Six Weeks, A Doula Service",
    "website": "http://lindajonesdoula.com",
    "email": "fst6wks@aol.com",
    "city": "Oakland",
    "phone": "510-740-8287",
    "state":"California"
  },
  {
    "name": "Birth Garden",
    "website": "https://birthgarden.com",
    "email": "anna@hurty.com",
    "city": "Oakland",
    "phone": "510-842-6283",
    "state":"California"
  },
  {
    "name": "Your Birth Team",
    "website": "https://yourbirthteam.com",
    "email": "yourbirthteam@gmail.com",
    "city": "Orange",
    "phone": "714-657-6409",
    "state":"California"
  },
  {
    "name": "Mum's the Word",
    "website": "https://mumstheword.biz",
    "email": "michele@mumstheword.biz",
    "city": "Pacific Grove",
    "phone": "831-238-0655"
  },
  {
    "name": "Coachella Valley Doulas",
    "website": "https://coachellavalleydoulas.com",
    "email": "hello@coachellavalleydoulas.com",
    "city": "Palm Desert",
    "phone": "760-349-2784",
    "state":"California"
  },
  {
    "name": "Blossom Birth and Family",
    "website": "https://blossombirthandfamily.org",
    "email": "blossom@blossombirthandfamily.org",
    "city": "Palo Alto",
    "phone": "650-321-2326",
    "state":"California"
  },
  {
    "name": "Pasadena Doula Associates",
    "website": "https://pasadenadoulas.com",
    "email": "pasadenadoulas@gmail.com",
    "city": "PASADENA",
    "phone": "626-552-9833",
    "state":"California"
  },
  {
    "name": "Sacred Hands Doula Services",
    "website": "https://sacredhandsdoula.com",
    "email": "sacredhandsdoula@gmail.com",
    "city": "Petaluma",
    "phone": "410-961-8769",
    "state":"California"
  },
  {
    "name": "Placerville Doula",
    "website": "https://placervilledoula.com",
    "email": "lindsay@placervilledoula.com",
    "city": "Placerville",
    "phone": "530-391-9188",
    "state":"California"
  },
  {
    "name": "Wonderfully Made Doula Services",
    "website": "https://wonderfullymadebirthdoula.com",
    "email": "doula.jacinda@gmail.com",
    "city": "Placerville",
    "phone": "530-417-4035",
    "state":"California"
  },
  {
    "name": "Your East Bay Doulas",
    "website": "https://youreastbaydoulas.com",
    "email": "YourEastBayDoulas@gmail.com",
    "city": "Pleasant Hill",
    "phone": "925-403-1745, 925-403-1475",
    "state":"California"
  },
  {
    "name": "Holly Wiersma, Doula Services",
    "website": "https://gotcontractions.com",
    "email": "hdwiersma@hotmail.com",
    "city": "Pleasant Hill",
    "phone": "925-408-6404, 925-947-5874",
    "state":"California"
  },
  {
    "name": "Milky Mama Breastfeeding Support & Doula Service",
    "website": "https://milky-mama.com",
    "email": "marketing@milky-mama.com",
    "city": "Rancho Cucamonga",
    "phone": "877-886-4559",
    "state":"California"
  },
  {
    "name": "Women's Healthcare Associates of Redding",
    "website": "https://dr4women.com",
    "email": "brenda@dr4women.com",
    "city": "Redding",
    "phone": "530-267-0863",
    "state":"California"
  },
  {
    "name": "Selah Women's Health",
    "website": "https://reddingobgyn.com",
    "email": "hello@selahwomenshealth.com",
    "city": "Redding",
    "phone": "530-338-0002",
    "state":"California"
  },
  {
    "name": "Redding Midwifery Group",
    "website": "https://reddingmidwiferygroup.com",
    "email": "reddingmidwifery@gmail.com",
    "city": "Redding",
    "phone": "530-768-5051",
    "state":"California"
  },
  {
    "name": "Fabulous Birth",
    "website": "https://fabulousbirth.com",
    "email": "fabienne@fabulousbirth.com",
    "city": "Redwood City",
    "phone": "415-990-8093",
    "state":"California"
  },
  {
    "name": "California Birth Center",
    "website": "https://calbirthcenter.com",
    "email": "info@calbirthcenter.com",
    "city": "Rocklin",
    "phone": "916-223-7731",
    "state":"California"
  },
  {
    "name": "Sutter Roseville Medical Center Birth Center",
    "website": "https://sutterhealth.org",
    "email": "hodgej@sutterhealth.org",
    "city": "Roseville",
    "phone": "916-781-1000",
    "state":"California"
  },
  {
    "name": "Sutter Roseville Medical Center Birth Center",
    "website": "https://sutterhealth.org",
    "email": "social@sutterhealth.org",
    "city": "Roseville",
    "phone": "916-781-1517",
    "state":"California"
  },
  {
    "name": "Serene Relaxation",
    "website": "https://serenerelaxation.com",
    "email": "RelaxationSerene@gmail.com",
    "city": "Sacramento",
    "phone": "916-226-0527",
    "state":"California"
  },
  {
    "name": "The Birth Center",
    "website": "https://sactobirth.com",
    "email": "info@sactobirth.com",
    "city": "Sacramento",
    "phone": "916-344-1860",
    "state":"California"
  },
  {
    "name": "Butler Anita",
    "website": "https://my3doulas.com",
    "email": "my3doulas@gmail.com",
    "city": "Sacramento",
    "phone": "916-524-1080",
    "state":"California"
  },
  {
    "name": "Cyndi's Doula Service",
    "website": "http://cyndidoula.net",
    "email": "cyndidoula1@gmail.com",
    "city": "Sacramento",
    "phone": "916-524-1267",
    "state":"California"
  },
  {
    "name": "Cypress Doula and Healing Services",
    "website": "https://cypressdoula.com",
    "email": "CypressDoula@gmail.com",
    "city": "Sacramento",
    "phone": "916-629-4844",
    "state":"California"
  },
  {
    "name": "Welcome Home Community Birth Center",
    "website": "https://communitybirthsac.org",
    "email": "hello@welcomehomems.org",
    "city": "Sacramento",
    "phone": "916-668-9467",
    "state":"California"
  },
  {
    "name": "Ruth Lundsten Doula Services",
    "website": "http://ruthlundstendoulaservices.com",
    "email": "irlundsten@aol.com",
    "city": "Sacramento",
    "phone": "916-718-8826",
    "state":"California"
  },
  {
    "name": "Mama in Mind Doula Services",
    "website": "https://mamainminddoula.com",
    "email": "mamainminddoula@gmail.com",
    "city": "Sacramento",
    "phone": "916-996-7240",
    "state":"California"
  },
  {
    "name": "Sierra Childbirth Institute",
    "website": "https://sierrachildbirthinstitute.com",
    "email": "info@sierrachildbirth.com",
    "city": "Sacramento",
    "phone": "916-999-0210",
    "state":"California"
  },
  {
    "name": "Doula Sabia",
    "website": "https://doulasabia.wordpress.com",
    "email": "doulasabia@gmail.com",
    "city": "San Diego",
    "phone": "201-257-7937",
    "state":"California"
  },
  {
    "name": "Best Start Birth Center",
    "website": "https://beststartbirthcenter.com",
    "email": "beststartclientcare@gmail.com",
    "city": "San Diego",
    "phone": "619-299-0840",
    "state":"California"
  },
  {
    "name": "Night and Day San Diego",
    "website": "https://nightanddaysandiego.com",
    "email": "hello@nightanddaysandiego.com",
    "city": "San Diego",
    "phone": "619-312-1832",
    "state":"California"
  },
  {
    "name": "A Joyful Doula",
    "website": "https://ajoyfuldoula.com",
    "email": "joy.kobrick@gmail.com",
    "city": "San Diego",
    "phone": "619-339-8556",
    "state":"California"
  },
  {
    "name": "My San Diego Doula & Placenta Encapsulation",
    "website": "https://mysandiegodoula.com",
    "email": "courtney@mysandiegodoula.com",
    "city": "San Diego",
    "phone": "619-618-8587",
    "state":"California"
  },
  {
    "name": "Doula Heather Taylor",
    "website": "https://doulaheathertaylor.com",
    "email": "doulaheathertaylor@gmail.com",
    "city": "San Diego",
    "phone": "619-729-6601",
    "state":"California"
  },
  {
    "name": "San Diego Postpartum Doula",
    "website": "http://sandiegopostpartumdoula.com",
    "email": "pmstrafford@cox.net",
    "city": "San Diego",
    "phone": "619-840-5413",
    "state":"California"
  },
  {
    "name": "Joyful Roots Doulas",
    "website": "https://sandiegocertifieddoulas.com",
    "email": "yourdoulateam@joyfulrootsdoulas.com",
    "city": "San Diego",
    "phone": "858-699-2710",
    "state":"California"
  },
  {
    "name": "Sharp Mary Birch Hospital for Women & Newborns",
    "website": "https://sharp.com",
    "email": "feedback@sharp.com",
    "city": "San Diego",
    "phone": "858-939-3400",
    "state":"California"
  },
  {
    "name": "Fruit of Life Birth & Wellness",
    "website": "http://fruitoflifeca.com",
    "email": "fruitoflifeca@gmail.com",
    "city": "San Diego",
    "phone": "949-485-9876",
    "state":"California"
  },
  {
    "name": "Birth Doula - Elizabeth Porter A San Francisco Doula",
    "website": "https://asanfranciscodoula.com",
    "email": "porterelizabeth59@gmail.com",
    "city": "San Francisco",
    "phone": "415-290-0271",
    "state":"California"
  },
  {
    "name": "Golden Gate Doula Associates",
    "website": "https://goldengatedoula.com",
    "email": "goldengatedoulas@gmail.com",
    "city": "San Francisco",
    "phone": "415-400-4680",
    "state":"California"
  },
  {
    "name": "Natural Resources",
    "website": "https://naturalresources-sf.com",
    "email": "info@naturalresources-sf.com",
    "city": "San Francisco",
    "phone": "415-550-2611",
    "state":"California"
  },
  {
    "name": "Your Best Birth Doula",
    "website": "http://yourbestbirthdoula.com",
    "email": "yourbestbirthdoula@gmail.com",
    "city": "San Francisco",
    "phone": "415-823-9104",
    "state":"California"
  },
  {
    "name": "O'Nell Starkey",
    "website": "https://onellstarkey.com",
    "email": "onellstarkey@gmail.com",
    "city": "San Francisco",
    "phone": "707-535-9309",
    "state":"California"
  },
  {
    "name": "Fog City Doula - Postpartum Services",
    "website": "https://fogcitydoula.com",
    "email": "dinadtoy@gmail.com",
    "city": "San Francisco",
    "phone": "720-438-1262",
    "state":"California"
  },
  {
    "name": "Janet Fishstrom Dombro, ibclc",
    "website": "https://janetbabymom.com",
    "email": "janet@janetbabymom.com",
    "city": "San Jose",
    "phone": "408-205-1234",
    "state":"California"
  },
  {
    "name": "South Bay Birth Doula",
    "website": "https://southbaybirthservices.com",
    "email": "southbaybirthservices@gmail.com",
    "city": "San Jose",
    "phone": "408-220-4814",
    "state":"California"
  },
  {
    "name": "Paula Calhoun Birth Doula",
    "website": "https://deliverydoula.com",
    "email": "paula@deliverydoula.com",
    "city": "San Jose",
    "phone": "408-761-5876",
    "state":"California"
  },
  {
    "name": "Patricia Madden, Doula Services & Prenatal Classes",
    "website": "http://doulasanjose.com",
    "email": "patriciam@mac.com",
    "city": "San Jose",
    "phone": "408-781-1129",
    "state":"California"
  },
  {
    "name": "Doula Meg | Birth and Postpartum Care",
    "website": "https://doulameg.com",
    "email": "megan@doulameg.com",
    "city": "San Jose",
    "phone": "510-676-0304",
    "state":"California"
  },
  {
    "name": "Birth Within Doula",
    "website": "https://birthwithindoula.com",
    "email": "birthwithindoula@gmail.com",
    "city": "San Luis Obispo",
    "phone": "805-801-1461",
    "state":"California"
  },
  {
    "name": "Stork & Sprout",
    "website": "https://storkandsprout.com",
    "email": "melissa@storkandsprout.com",
    "city": "San Mateo",
    "phone": "650-227-3223",
    "state":"California"
  },
  {
    "name": "Beach City Doula",
    "website": "https://beachcitydoula.com",
    "email": "frontdesk643@yahoo.com",
    "city": "San Pedro",
    "phone": "310-831-5700",
    "state":"California"
  },
  {
    "name": "From The Start, Postpartum Doula",
    "website": "https://fromthestartdoula.com",
    "email": "fromthestartdoula@gmail.com",
    "city": "San Pedro",
    "phone": "443-619-7315",
    "state":"California"
  },
  {
    "name": "Milk & Swaddles",
    "website": "https://milkandswaddles.com",
    "email": "kelly@milkandswaddles.com",
    "city": "San Rafael",
    "phone": "415-412-5104",
    "state":"California"
  },
  {
    "name": "Inner Strength Birth Services LLC-Doula",
    "website": "https://innerstrengthbirth.com",
    "email": "meleah@innerstrengthbirth.com",
    "city": "San Rafael",
    "phone": "951-313-5202",
    "state":"California"
  },
  {
    "name": "Be Always Blooming Doula Services",
    "website": "https://bealwaysblooming.com",
    "email": "kaitlin@bealwaysblooming.com",
    "city": "Santa Barbara",
    "phone": "805-794-7425",
    "state":"California"
  },
  {
    "name": "Santa Barbara Birth Center",
    "website": "https://sbbirthcenter.org",
    "email": "sbmidwifery@gmail.com",
    "city": "Santa Barbara",
    "phone": "805-770-3700",
    "state":"California"
  },
  {
    "name": "Tender Bees Doula Services",
    "website": "https://tenderbeesdoula.com",
    "email": "cali@tenderbeesdoula.com",
    "city": "Santa Clarita",
    "phone": "661-309-2955, 661-513-4423",
    "state":"California"
  },
  {
    "name": "Doula Darla",
    "website": "https://douladarla.com",
    "email": "darla@douladarla.com",
    "city": "Santa Clarita",
    "phone": "661-645-3726",
    "state":"California"
  },
  {
    "name": "Wombmate",
    "website": "https://womb-mate.com",
    "email": "wombmatewanted@gmail.com",
    "city": "Santa Clarita",
    "phone": "818-533-8115",
    "state":"California"
  },
  {
    "name": "Inner Light Birth Services",
    "website": "https://innerlightsmh.wixsite.com/innerlightcoachdoula",
    "email": "innerlightsmh@gmail.com",
    "city": "Santa Cruz",
    "phone": "610-420-8701, 571-435-0994",
    "state":"California"
  },
  {
    "name": "Blessed Birth Doula Care",
    "website": "https://blessedbirth.net",
    "email": "blessedbirth@gmail.com",
    "city": "Santa Cruz",
    "phone": "831-566-1167",
    "state":"California"
  },
  {
    "name": "Beth Lilienthal; Postpartum Doula Care Santa Cruz",
    "website": "https://santacruzbabydoula.com",
    "email": "beththedoula@gmail.com",
    "city": "Santa Cruz",
    "phone": "831-588-7367",
    "state":"California"
  },
  {
    "name": "Mothering the Mothers Inc.Doula Services.",
    "website": "https://motheringthemothers.com",
    "email": "motheringthemothers@gmail.com",
    "city": "Santa Monica",
    "phone": "310-804-5517",
    "state":"California"
  },
  {
    "name": "Thrive Birth Center",
    "website": "http://thrivebirth.com",
    "email": "info@thrivebirth.com",
    "city": "Santa Rosa",
    "phone": "707-387-2088",
    "state":"California"
  },
  {
    "name": "Moncure Massage & Doula Care",
    "website": "http://moncuremassage.com",
    "email": "elizabethmoncure27@gmail.com",
    "city": "Seattle",
    "phone": "206-697-6180",
    "state":"California"
  },
  {
    "name": "Bini Birth",
    "website": "https://binibirth.com",
    "email": "info@binibirth.com",
    "city": "Sherman Oaks",
    "phone": "818-286-3944",
    "state":"California"
  },
  {
    "name": "Blissful Birth Doula Services & Placenta Encapsulation",
    "website": "https://blissfulbirthdoula.com",
    "email": "blissbirthing@gmail.com",
    "city": "Sonoma County",
    "phone": "415-409-9462",
    "state":"California"
  },
  {
    "name": "Primrose and Lace Lingerie & Doula Services",
    "website": "http://primroseandlace.com",
    "email": "scprimroseandlace@gmail.com",
    "city": "Soquel",
    "phone": "831-325-5030",
    "state":"California"
  },
  {
    "name": "Del Mar Birth Center",
    "website": "https://delmarbirthcenter.com",
    "email": "info@delmarbirthcenter.com",
    "city": "South Pasadena",
    "phone": "626-577-2229",
    "state":"California"
  },
  {
    "name": "Inland Empire Doula",
    "website": "http://inlandempiredoula.com",
    "email": "belleraebirthservices@yahoo.com",
    "city": "Temecula",
    "phone": "951-368-4477",
    "state":"California"
  },
  {
    "name": "Birch Family Birth Services",
    "website": "https://birchfamilybirthservices.com",
    "email": "info@birchfamilybirthservices.com",
    "city": "Thousand Oaks",
    "phone": "805-380-7771",
    "state":"California"
  },
  {
    "name": "MAMAMOON Postpartum Doula Services",
    "website": "https://www.facebook.com/mamamoonpostpartumcare/about/?ref=page_internal",
    "email": "mamamoonpostpartumcare@gmail.com",
    "city": "Topanga",
    "phone": "805-455-4519",
    "state":"California"
  },
  {
    "name": "BioMama Birth and Mamacare",
    "website": "https://biomamabirth.com",
    "email": "biomamabirth@gmail.com",
    "city": "Torrance",
    "phone": "213-215-0112",
    "state":"California"
  },
  {
    "name": "Le Gra Birth Services",
    "website": "https://legrabirth.com",
    "email": "Brieana@legrabirth.com",
    "city": "Torrance",
    "phone": "951-397-6264",
    "state":"California"
  },
  {
    "name": "Namaste Holistic Healing & Yoga Center",
    "website": "https://namastetruckee.com",
    "email": "am@namastetruckee.com",
    "city": "Truckee",
    "phone": "530-448-9014",
    "state":"California"
  },
  {
    "name": "Tahoe Doula, Andrea W. Schwartz",
    "website": "https://tahoedoula.com",
    "email": "ntahoedoula@gmail.com",
    "city": "Truckee",
    "phone": "530-580-8166",
    "state":"California"
  },
  {
    "name": "Three Feathers Holistic: Energy Healing & Wellness",
    "website": "https://threefeathersholistic.com",
    "email": "tanya@threefeathersholistic.com",
    "city": "Truckee",
    "phone": "775-742-5936",
    "state":"California"
  },
  {
    "name": "Labor of Love Birth & Postpartum",
    "website": "https://laboroflovebirthservices.com",
    "email": "info@laboroflovebirthservices.com",
    "city": "Tustin",
    "phone": "562-260-0636",
    "state":"California"
  },
  {
    "name": "Babylove Doula",
    "website": "https://babylovencp.com",
    "email": "christy.gochez@babylovencp.com",
    "city": "Tustin",
    "phone": "949-690-4606",
    "state":"California"
  },
  {
    "name": "New Birth Services",
    "website": "https://newbirthservices.com",
    "email": "info@newbirthservices.com​",
    "city": "Vallejo",
    "phone": "707-563-1171",
    "state":"California"
  },
  {
    "name": "The Birth Coop",
    "website": "http://thebirthcoop.com",
    "email": "birthcoopdoulas@gmail.com",
    "city": "Van Nuys",
    "phone": "818-636-2223",
    "state":"California"
  },
  {
    "name": "AATBS",
    "website": "https://aatbs.com",
    "email": "info@aatbs.com",
    "city": "Ventura",
    "phone": "530-533-8500, 800-472-1931",
    "state":"California"
  },
  {
    "name": "The Luxe Baby",
    "website": "https://theluxebaby.com",
    "email": "theluxebaby@yahoo.com",
    "city": "Ventura",
    "phone": "805-258-7011",
    "state":"California"
  },
  {
    "name": "Leidos",
    "website": "https://leidos.com",
    "email": "AskHR@leidos.com",
    "city": "Vista",
    "phone": "571-526-6000",
    "state":"California"
  },
  {
    "name": "Hunter Douglas",
    "website": "https://hunterdouglas.com",
    "email": "nicole.patterson@hunterdouglas.com",
    "city": "West Sacramento",
    "phone": "800-789-0331, 916-453-1212",
    "state":"California"
  },
  {
    "name": "Birthwell Los Angeles",
    "website": "http://birthwell.com",
    "email": "info@birthwell.com",
    "city": "Woodland Hills",
    "phone": "424-256-8727",
    "state":"California"
  },
  {
    "name": "Heather Mayer Doula",
    "website": "http://heathermayerdoula.com",
    "email": "heather@heathermayerdoula.com",
    "city": "Woodland Hills",
    "phone": "818-914-9914",
    "state":"California"
  },

  {
    "name": "Johnann Johnson Living Happy & Well",
    "website": "http://johnannjohnson.com",
    "email": "johnannjohnson@gmail.com",
    "city": "Yuba City",
    "phone": "530-300-5097",
    "state":"California"
  }
]
